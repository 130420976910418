
.t-cabecalho .menus {

    .menu-1 {
      padding: 25px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .redes-sociais {
        background: #D2D2D2;
        color: white;
        padding: 10px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        .icone {
          vertical-align: middle;
          height: 1.25em;
          line-height: 1.25em;
        }
      }
      .button {
        font-size: 19px;
        margin-right: 15px;
        font-family: ubuntu-bold;
        &:last-child {
          margin-right: 0;
        }
      }

      .direita {

        .button {
            cursor: pointer;
            justify-content: center;
            padding-bottom: calc(.375em - 1px);
            padding-left: .75em;
            padding-right: .75em;
            padding-top: calc(.375em - 1px);
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            justify-content: center;
            padding-bottom: calc(.375em - 1px);
            padding-left: .75em;
            padding-right: .75em;
            padding-top: calc(.375em - 1px);
            text-align: center;
            white-space: nowrap;
            font-style: none;
            text-decoration: none;
            height: 2.25em;
            display: inline-block;
        }
      }
    }
    .menu-2 {
      background: #005A70;
      .container {
        padding: 50px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .atalho-menu {
        color: white;
        text-shadow: 0 0 1px #dedede;
        letter-spacing: 5px;
        font-size: 13px;
        text-decoration: none;
      }
      .ponto {
        font-size: 5px;
        vertical-align: middle;
        margin: 0 20px;
        color: #004252;
      }
      .esquerda {
        flex-grow: 1;
        text-align: right;
        max-width: calc(50% - 170px);
      }
      .direita {
        flex-grow: 1;
        max-width: calc(50% - 170px);

        
      }
    }
    .emblema {
      position: absolute;
      width: 260px;
      background: #004252;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      text-align: center;
      padding: 30px;
      padding-top: 70px;
      z-index: 1;
      img {
        vertical-align: middle;
        max-width: 100%;
      }
      .logo-2 {
        display: none;
      }
    }
  }
  
  @media screen and (max-width: 908px) {
    .t-cabecalho .menus {
      .menu-1 {
        .esquerda {
          text-align: center;
          flex-grow: 1;
          max-width: calc(50% - 170px);
        }
        .direita {
          flex-grow: 1;
          max-width: calc(50% - 170px);
          .button {
            display: block;
            margin: auto;
            margin-top: 10px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
      .menu-2 {
        .container {
          padding: 30px 10px;
        }
        .atalho-menu {
          display: block;
          margin-top: 15px;
          &:first-child {
            margin-top: 0;
          }
        }
        .ponto {
          display: none;
        }
      }
      .emblema {
        width: 230px;
        padding-top: 100px;
      }
    }
  }
  
  @media screen and (max-width: 649px) {
    .t-cabecalho .menus {
      .menu-1 {
        .esquerda {
          text-align: center;
          flex-grow: 1;
          max-width: calc(50% - 60px);
        }
        .direita {
          flex-grow: 1;
          max-width: calc(50% - 60px);
          .button {
            display: block;
            margin: auto;
            margin-top: 10px;
            font-size: 16px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        .redes-sociais {
          margin: 0;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .menu-2 {
        .container {
          padding: 30px 10px;
          .esquerda, .direita {
            max-width: none;
          }
        }
        .atalho-menu {
          display: inline;
          margin-left: 15px;
          &:first-child {
            margin-top: 0;
          }
        }
        .ponto {
          display: none;
        }
      }
      .emblema {
        width: 90px;
        background: none;
        padding: 0;
        padding-top: 21px;
        .logo-1 {
          display: none;
        }
        .logo-2 {
          display: block;
        }
      }
    }
  }
  
  @media screen and (max-width: 420px) {
    .t-cabecalho .menus .menu-2 {
      .container {
        flex-direction: column;
      }
      .atalho-menu {
        flex-grow: 1;
        text-align: center;
        margin: 0;
      }
      .esquerda {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
      }
      .direita {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  
  @media screen and (max-width: 375px) {
    .t-cabecalho .menus {
      .menu-1 {
        .esquerda {
          max-width: calc(50% - 50px);
        }
        .direita {
          max-width: calc(50% - 50px);
          .button {
            font-size: 14px;
          }
        }
        .redes-sociais {
          padding: 5px;
        }
      }
      .emblema {
        width: 70px;
      }
    }
  }
  
  @media screen and (max-width: 320px) {
    .t-cabecalho .menus {
      .menu-1 {
        .esquerda {
          max-width: calc(50% - 40px);
        }
        .direita {
          max-width: calc(50% - 40px);
          .button {
            font-size: 14px;
          }
        }
        .redes-sociais {
          padding: 5px;
        }
      }
      .emblema {
        width: 60px;
      }
    }
  }
  