
@import '~bootstrap/scss/bootstrap.scss';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@font-face {
    font-family: ubuntu-bold;
    src: url(./assets/fontes/Ubuntu-Bold.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-bold-italic;
    src: url(./assets/fontes/Ubuntu-BoldItalic.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-italic;
    src: url(./assets/fontes/Ubuntu-Italic.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-light;
    src: url(./assets/fontes/Ubuntu-Light.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-light-italic;
    src: url(./assets/fontes/Ubuntu-LightItalic.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-medium;
    src: url(./assets/fontes/Ubuntu-Medium.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-medium-italic;
    src: url(./assets/fontes/Ubuntu-MediumItalic.ttf);
  }
  
  
  @font-face {
    font-family: ubuntu-regular;
    src: url(./assets/fontes/Ubuntu-Regular.ttf);
  }
  
  

html {
    font-size: 14px;
    overflow: auto;
    font-family: "ubuntu-regular",Helvetica,Arial,sans-serif;
    line-height: 1.42857143;
    color: #333;
  }
  
  body {
    font-size: 14px;
    overflow: auto;
    line-height: 1.42857143;
    color: #333;
    font-family: 'ubuntu-regular', arial;
  }
  
  
button, input, select, textarea {
    font-family: 'ubuntu-regular', arial;
  }
  
  .button {
    &:focus:not(:active) {
      outline: 0;
      box-shadow: none;
    }
    &.cor-2 {
      border: none;
      background: #8b1111;
      color: white;
    }
    &.cor-3 {
      background: #D2D2D2;
      border: none;
      box-shadow: 0 0 2px #adadad;
      border-radius: 30px;
      color: white;
      text-shadow: 0 0 1px #adadad;
      &:hover {
        border: none;
        background: #adadad;
      }
    }
    &.cor-4 {
      background: #F39C12;
      border: none;
      box-shadow: 0 0 2px #adadad;
      border-radius: 30px;
      color: white;
      text-shadow: 0 0 1px #adadad;
      &:hover {
        border: none;
        background: #DE962D;
      }
    }
  }
  
  .input {
    &:focus, &:active {
      outline: 0;
      box-shadow: none;
      border-color: #a1a1a1;
      box-shadow: 0 0 0 0.125em rgba(161, 161, 161, 0.17);
    }
  }
  
  .select select {
    &:focus, &:active {
      outline: 0;
      box-shadow: none;
      border-color: #a1a1a1;
      box-shadow: 0 0 0 0.125em rgba(161, 161, 161, 0.17);
    }
  }
  

  .has-text-centered {
    text-align: center!important;
  }