
.t-rodape {
    .info {
      margin-top: 100px;
      background: linear-gradient(rgba(7, 42, 56, 0.94), rgba(7, 42, 56, 0.94)), url("./assets/banners/shutterstock_1021252312.jpg") center no-repeat;
      background-size: 100% 100%, cover;
      .container {
        display: flex;
        align-items: center;
        padding: 80px 0;
        > div {
          width: 33.33333333333%;
          flex-grow: 1;
        }
        .titulo-1 {
          font-size: 2.7vw;
          line-height: 2.7vw;
          color: #32586B;
          font-family: ubuntu-bold;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            height: 8px;
            width: 70px;
            background: #32586B;
            top: -20px;
            left: 0;
          }
        }
        .redes-sociais {
          margin-top: 30px;
          a {
            display: block;
            display: flex;
            align-items: center;
            padding: 10px;
            font-size: 22px;
            .icone {
              margin-right: 10px;
              background: #DE962D;
              border-radius: 100%;
              width: 31px;
              text-align: center;
              color: #004252;
            }
            .desc {
              color: white;
            }
          }
        }
        .logo-rodape {
          text-align: center;
        }
        .menu {
          font-size: 22px;
          color: white;
          .links {
            margin-top: 20px;
            a {
              color: #DE962D;
              display: block;
            }
            .espaco {
              height: 20px;
            }
          }
        }
      }
    }
    .info-2 {
      background: linear-gradient(rgba(7, 42, 56, 0.83), rgba(7, 42, 56, 0.83));
      text-align: center;
      font-size: 22px;
      color: white;
      vertical-align: middle;
      .container {
        padding: 15px;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .t-rodape .info .container {
      padding: 80px 20px;
      > div {
        width: 40%;
        flex-grow: 1;
        padding: 10px;
      }
      .logo-rodape {
        width: 20%;
        padding: 0;
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    .t-rodape .info .container {
      padding: 80px 20px;
      > div {
        width: 40%;
        padding: 10px;
      }
      .titulo-1 {
        font-size: 22px;
        line-height: 24px;
      }
      .redes-sociais a {
        font-size: 17px;
        .icone {
          width: 26px;
        }
      }
      .logo-rodape {
        width: 20%;
        padding: 0;
      }
      .menu {
        font-size: 17px;
      }
    }
  }
  
  @media screen and (max-width: 550px) {
    .t-rodape .info .container {
      > div {
        width: 49%;
      }
      .logo-rodape {
        display: none;
      }
    }
  }
  