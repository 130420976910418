.t-pagina-inicio {
    margin-top: 20px;
    .banners {
      overflow: hidden;
      position: relative;
      .blocos {
        height: 100%;

        #bloco-slick.bloco {
            display: flex !important;
        }

        .bloco {
          display: flex;
          height: 100%;
          cursor: -webkit-grab;
          height: calc(100vh - 400px);
          min-height: 400px;
          background: 'orange';

          &:focus {
            outline: 0;
          }
          .imagem {
            width: calc(50% + 70px);
            background-position: center;
            object-fit: contain;
            height: 100%;
            z-index: -1;
          }
          .descricao {
            width: calc(50% - 70px);
            background: orange;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: orange;
            &:before {
              content: "";
              width: 40px;
              height: 40px;
              background: var(--cor-fundo);
              position: absolute;
              bottom: 100px;
              border-bottom-left-radius: 10px;
              transform: rotate(45deg);
              left: -20px;
              z-index: -1;
            }
            p {
              max-width: 15ch;
              margin: 40px;
              color: white;
              font-size: 3.5vw;
              line-height: 3.5vw;
            }
          }
        }
      }
      .pontos {
        height: 25px;
        .slick-dots {
          bottom: -25px;
          display: block;
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          text-align: center;
          li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            &.slick-active button:before {
              opacity: .75;
              color: black;
            }
            button {
              font-size: 0;
              line-height: 0;
              display: block;
              width: 20px;
              height: 20px;
              padding: 5px;
              cursor: pointer;
              color: transparent;
              color: red;
              border: 0;
              outline: none;
              background: transparent;
              &:before {
                font-family: 'slick';
                font-size: 50px;
                line-height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                content: '•';
                text-align: center;
                opacity: .25;
                color: black;
                -webkit-font-smoothing: antialiased;
              }
            }
          }
        }
      }
    }
    .dividor-pagina {
      height: 15px;
      background: url("./assets/pixel1.png") center repeat;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .bloco-funcionalidades {
      padding: 10px;
      .titulo-funcionalidades {
        font-size: 4.0vw;
        line-height: 4.0vw;
        font-family: ubuntu-bold;
        color: #DE962D;
        position: relative;
        margin-top: 95px;
        &:after {
          height: 7px;
          width: 5vw;
          background: #19586C;
          content: "";
          position: absolute;
          top: -35px;
          left: 0;
        }
        span {
          color: #19586C;
        }
      }
      .subtitulo-funcionalidades {
        color: #19586C;
        font-size: 22px;
      }
      .funcionalidades {
        display: flex;
        justify-content: center;
        margin: 60px 0;
        .funcionalidade {
          max-width: 200px;
          padding: 10px;
          margin: 0 20px;
          .imagem-funcionalidade {
            background: #19586C;
            border-radius: 100% 100% 100% 100%;
            padding: 30px 30px 30px 30px;
            width: 80%;
            margin: auto;
            margin-bottom: 12px;
          }
          img {
            margin: auto;
            display: block;
            width: 100%;
          }
          p {
            color: #19586C;
            font-size: 19px;
            text-align: center;
          }
        }
      }
      .botao-saiba-mais {
        font-size: 19px;
        position: relative;
        padding-left: 56px;

        padding-right: .75em;
        padding-top: calc(.375em - 1px);
        text-align: center;
        white-space: nowrap;
        cursor: pointer;
        justify-content: center;
        padding-bottom: calc(.375em - 1px);
        display: inline-flex;
        height: 2.25em;
        line-height: 1.5em;

        b {
          margin-right: 4px;
        }
        .sinal {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -3px;
          background: #DE962D;
          border: none;
          box-shadow: 0 0 2px #adadad;
          border-radius: 30px;
          color: white;
          text-shadow: 0 0 1px #adadad;
          padding: 3px;
          border-radius: 100%;
          transform: scale(1.4);
          .icone {
            vertical-align: middle;
            font-size: 29px;
            padding-top: 5px;
          }
        }
      }
    }
    .bloco-experimentar {
      margin-top: 100px;
      .topo {
        background: #19586C;
        padding: 70px 20px;
        text-align: center;
        .button {
          color: #19586C;
          background: white;
          font-family: ubuntu-bold;
          font-size: 2vw;
          border-radius: 60px;
          padding: 0 50px;
          display: inline-block;
          text-decoration: none;

          span {
            color: #DE962D;
            white-space: pre-wrap;
          }
        }
      }
      .imagem-banner {
        min-height: 700px;
        height: calc(100vh - 270px);
        object-fit: cover;
        width: 100%;
      }
    }
    .bloco-funcionalidades-lista {
      .container {
        padding: 10px;
      }
      .titulo-bloco {
        font-family: ubuntu-bold;
        display: inline-block;
        padding: 15px 30px;
        border-radius: 25px;
        font-size: 40px;
        color: white;
        position: relative;
        span {
          color: #19586C;
        }
        &:after {
          content: "";
          position: absolute;
          background: #F39C12;
          transform: skew(-8deg);
          border-radius: 25px;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
        }
      }
      .lista {
        margin: 50px 0;
        list-style: none;

        li {
          color: #005A70;
          font-size: 22px;
          margin: 10px 0;
          padding-left: 35px;
          position: relative;
          &:after {
            content: "";
            background: #005A70;
            position: absolute;
            height: 3px;
            width: 20px;
            left: 5px;
            top: 17px;
          }
        }
      }
    }
    .bloco-quem-somos {
      position: relative;
      margin-bottom: 200px;
      display: flex;
      align-items: center;
      background: #19586C;
      .imagem {
        max-width: 50%;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-top-right-radius: 70px;
          border-bottom-right-radius: 70px;
          position: relative;
          bottom: -120px;
        }
      }
      .textos {
        padding: 20px;
        flex-grow: 1;
        position: relative;
        .titulo-bloco {
          color: #DE962D;
          font-size: 34px;
          font-family: ubuntu-bold;
          margin-top: 50px;
        }
        p {
          color: white;
          font-size: 22px;
          max-width: 50ch;
        }
        .dividor-pagina {
          width: 96%;
          position: absolute;
          bottom: -15vw;
        }
      }
    }
    .bloco-cliente {
      .container {
        padding: 10px;
      }
      .bloco-titulo {
        font-size: 3.0vw;
        line-height: 3.0vw;
        font-family: ubuntu-bold;
        color: #F39C12;
      }
      .bloco-subtitulo {
        color: #005A70;
        font-size: 22px;
      }
      .planos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        .plano {
          background: #19586C;
          max-width: 300px;
          color: white;
          font-family: ubuntu-bold;
          margin: 0 3px;
          p {
            padding: 20px;
            text-align: center;
            color: #DE962D;
            font-size: 22px;
          }
          .valor {
            padding: 20px;
            background: #004252;
            text-align: center;
            font-size: 40px;
          }
          .opcao {
            padding: 20px;
            text-align: center;
            .button {
              font-family: ubuntu-bold;
              color: #004252;
              font-size: 18px;
              display: inline-block;
              text-decoration: none;
              cursor: pointer;
              justify-content: center;
              padding-bottom: calc(.375em - 1px);
              padding-left: .75em;
              padding-right: .75em;
              padding-top: calc(.375em - 1px);
              text-align: center;
              white-space: nowrap;
            }
          }
          &.destaque {
            transform: scale(1.2);
            margin: 0 30px;
          }
        }
      }
    }
  }
  